import React, { Component } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Typical from "react-typical";
import Seo from "../components/seo";
import Navbar from "../components/navbar";
import Sidenote from "../components/sidenote";
import Marginnote from "../components/marginnote";
import Resume from "../documents/rishi-goutam-resume.pdf";
import "../styles/global.scss";
import "./home.module.scss";

function citibike_sidenote() {
  const noteNode = (
    <>
      <div style={{ textAlign: "center" }}>
        Citi Bike stations and rebalancing routes
      </div>
    </>
  );
  const imageNode = (
    <>
      <a href="/topstations.html" target="_blank">
        <StaticImage
          src="../images/figure-1.png"
          alt="Map of Citi Bike stations by number of rebalances"
          title="Open visualization"
        />
      </a>
    </>
  );
  const imageCaptionNode = (
    <>
      {/* Colors are from stations.ipynb from the gh://rishigoutam/citibike project */}
      <div style={{ textAlign: "center" }}>
        <em>Bikes are moved from </em>
        <span style={{ color: "rgb(51,51,255,150)" }}>
          <strong>blue</strong>
        </span>
        <em> to </em>
        <span style={{ color: "rgb(255,51,51,150)" }}>
          <strong>red</strong>
        </span>
        <em> stations.</em>
      </div>
    </>
  );
  const note = { title: noteNode, image: imageNode, caption: imageCaptionNode };

  return (
    <>
      <Marginnote nodes={note} />
    </>
  );
}

function memoji_figure() {
  return (
    <>
      <StaticImage
        src="../images/memoji.png"
        alt="Memoji avatar of Rishi Goutam"
        as="figure"
        width={200}
      />
    </>
  );
}

const SubtitleAnimation = React.memo(
  () => {
    const steps = [
      1000,
      "Hello 👋",
      2000,
      "I'm a ▏",
      200,
      "I'm a ",
      200,
      "I'm a ▏",
      200,
      "I'm a ",
      200,
      "I'm a ▏",
      200,
      "I'm a ",
      200,
      "I'm a dad",
      1500,
      "I'm a developer",
      1000,
      "I'm a tech strategy consultant",
      1000,
      "I'm a data scientist",
      1000,
      "Data Scientist & Software Engineer",
      300,
      "Data Scientist & Software Engineer ▏",
      300,
      "Data Scientist & Software Engineer",
      300,
      "Data Scientist & Software Engineer ▏",
      300,
      "Data Scientist & Software Engineer",
      300,
      "Data Scientist & Software Engineer ▏",
      300,
      "Data Scientist & Software Engineer",
      300,
      "Data Scientist & Software Engineer ▏",
      300,
      "Data Scientist & Software Engineer",
    ];

    return <Typical steps={steps} wrapper="span" />;
  },
  (props, prevProp) => true
);

class Home extends Component {
  render() {
    return (
      <>
        <Seo
          title="Rishi Goutam—Data Scientist &amp; Software Engineer"
          vercel={{ title: "A site about me, my projects, writings, etc…" }}
        />
        <article>
          <header>
            <Navbar backNavbar={false} />
            <h1>Rishi Goutam</h1>
            <p className="subtitle">
              <SubtitleAnimation />
            </p>
          </header>
          <section>
            <h2>About me</h2>
            <p>I love all things software, data science, and cats.</p>
            <p>
              {citibike_sidenote()} I recently graduated from the{" "}
              <a href="https://nycdatascience.edu/about-us/">
                NYC Data Science Academy
              </a>{" "}
              and am currently looking for a new role. You can check out my{" "}
              <Link to="/projects/citi-bike-rebalances">capstone</Link> project
              ☛
            </p>
            <p>
              Recently, I worked as a technology strategy consultant for banking
              and insurance clients. Previously, I was a front-end engineer at
              Microsoft
              <Sidenote note="Azure Data Factory & Data Catalog teams" />
              , and an Android developer at Amazon
              <Sidenote note="Kindle team" />. I enjoy playing Age of Empires
              II, biking, and tennis (and am always looking for a partner on the
              court).
            </p>
            <p>
              You can find my work on{" "}
              <a
                href="https://www.github.com/rishigoutam/"
                title="Rishi Goutam's GitHub page"
              >
                GitHub
              </a>
              .
            </p>
            <p>
              Finally, here's my{" "}
              <a href={Resume} target="_blank">
                resume
              </a>
              .
            </p>
            <h2>Contact</h2>
            <p>
              Feel free to reach out via{" "}
              <a
                href="mailto:rishi@goutam.io"
                title="Rishi Goutam's email address"
              >
                email
              </a>
              , through{" "}
              <a
                href="https://www.linkedin.com/in/rishigoutam"
                title="Rishi Goutam's LinkedIn page"
              >
                LinkedIn
              </a>
              , or the <mark className="chat">chat</mark> on this page ☛
            </p>
            <p>
              You can also send me a{" "}
              <a
                href="https://calendly.com/rishigoutam/30-minute-meeting"
                title="Set up meeting on Rishi Goutam's calendar"
              >
                calendar invite
              </a>
              .
            </p>
            {memoji_figure()}
          </section>
        </article>
      </>
    );
  }
}

export default Home;
